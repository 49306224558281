// routes.js
import { lazy } from "react";

const routes = [
  { path: "/", component: lazy(() => import("./pages/Home/Home")) },
  { path: "/detail", component: lazy(() => import("./pages/DetailPage/DetailPage")) },
  { path: "/TouchDiffusion", component: lazy(() => import("./pages/DetailPage/TouchDiffusionDetail")) },
  { path: "/Photon", component: lazy(() => import("./pages/DetailPage/PhotonDetail")) },
  { path: "/EyeSay", component: lazy(() => import("./pages/DetailPage/EyeSayDetail")) },
  { path: "/Where", component: lazy(() => import("./pages/DetailPage/WhereDetail")) },
  { path: "/FunFine", component: lazy(() => import("./pages/DetailPage/FunFineDetail")) },
  { path: "/LeftLife", component: lazy(() => import("./pages/DetailPage/LeftLifeDetail")) },
  { path: "/IntuitModeling", component: lazy(() => import("./pages/DetailPage/IntuitModelingDetail")) },
  { path: "/FusionProtor", component: lazy(() => import("./pages/DetailPage/FusionProtorDetail")) },
  { path: "/IEDS", component: lazy(() => import("./pages/DetailPage/IEDSDetail")) },
  { path: "/SocializeChat", component: lazy(() => import("./pages/DetailPage/SocializeChatDetail")) },
  { path: "/VRGesture", component: lazy(() => import("./pages/DetailPage/VRGestureDetail")) },
  { path: "/Xining", component: lazy(() => import("./pages/DetailPage/XiningDetail")) },
  { path: "/Qingshanhu", component: lazy(() => import("./pages/DetailPage/QingshanhuDetail")) },
  { path: "/Faxisi", component: lazy(() => import("./pages/DetailPage/FaxisiDetail")) },
  { path: "/HybridPrototype", component: lazy(() => import("./pages/DetailPage/HybridPrototypeDetail")) },
];

export default routes;
