import React, { createContext, useContext, useState, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home/Home';
import DetailPage from './pages/DetailPage/DetailPage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NoReadyImg from './img/noReady.jpg';
import Spacer from './components/Spacer/Spacer';
import ReactMarkdown from 'react-markdown';
import routes from './routes';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [tabState, setTabState] = useState('1'); // 默认 Tab 为 'tab1'
  
  return (
    <AppContext.Provider value={{ tabState, setTabState }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

// // 动态加载 Markdown 文件
// const markdownFiles = require.context('./markdown', false, /\.md$/);

// // 动态生成路由组件
// const routeComponents = markdownFiles.keys().reduce((components, filePath) => {
//   const fileName = filePath.replace('./', '').replace('.md', ''); // 获取文件名

//   // 加载 Markdown 文件内容
//   const markdownContent = markdownFiles(filePath).default || markdownFiles(filePath);

//   // 定义自定义渲染组件
//   const markdownComponents = {
//     h1: ({ children }) => <h1 style={{ color: 'blue', fontWeight: 'bold' }}>{children}</h1>, // 自定义 h1 渲染
//   };

//   // 为每个 Markdown 文件生成一个 React 组件
//   components[fileName] = () => (
//     <div>
//       <ReactMarkdown components={markdownComponents}>{markdownContent}</ReactMarkdown>
//     </div>
//   );

//   return components;
// }, {});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
            {routes.map(({ path, component: Component }, index) => (
                <Route key={index} path={path} element={<Component />} />
            ))}
            {/* {Object.entries(routeComponents).map(([name, Component]) => (
              <Route key={name} path={`/${name}`} element={<Component />} />
            ))} */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
