import styles from './Home.module.css';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import Navigator from '../../components/Navigator/Navigator';
import TouchDiffusionImg from '../../img/touchdiffusion.jpg';
import IntuitModelingImg from '../../img/IntuitModeling.jpg';
import FusionProtorImg from '../../img/FusionProtor.jpg';
import VRGestureImg from '../../img/VRGesture.jpg';
import SocializeChatImg from '../../img/SocializeChat.jpg';
import HybridPrototypeImg from '../../img/HybridPrototype.jpg';
import EyeSayImg from '../../img/EyeSay.jpg';
import FunFineImg from '../../img/FunFine.jpg';
import WhereImg from '../../img/Where.jpg';
import LeftLifeImg from '../../img/LeftLife.jpg';
import PhotonImg from '../../img/Photon.jpg';
import XiningImg from '../../img/Xining.jpg';
import QingshanhuImg from '../../img/Qingshanhu.jpg';
import FaxisiImg from '../../img/Faxisi.jpg';
import IEDSImg from '../../img/IEDS.jpg';
import DIA from '../../svg/DIA.svg';
import '../../App.css'
import { Tabs } from 'antd';
import { ConfigProvider } from 'antd';
import Spacer from '../../components/Spacer/Spacer';
import Chip from '../../components/Chip/Chip';
import avatar from '../../img/avatar.jpg'
import { Anchor, Row} from "antd";
import React, { useState, useEffect } from 'react';
import {useLocation} from'react-router-dom';
import { useAppContext } from '../..';

const onChange = (key) => {
    console.log(key);
};

// https://ant.design/components/tabs-cn
// https://ant.design/components/anchor-cn

function Home() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 576);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [is1024Mobile, setIs1024Mobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => setIs1024Mobile(window.innerWidth <= 1024);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { tabState, setTabState } = useAppContext();

    const { state } = useLocation();

    useEffect(() => {
        if (state && state.tabState) {
            setTabState(state.tabState); // 恢复 Tab 状态
        }
    }, [state, setTabState]);

    return (
        <div>
            <Navigator></Navigator>
            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            itemSelectedColor: '#333333', 
                            itemActiveColor: '#333333', // 激活 Tab 的主色
                            itemHoverColor:'#666666',
                            itemColor: '#999999', // Tab 文本颜色
                            inkBarColor: '#333333', // 激活 Tab 的下划线颜色
                            horizontalItemGutter: is1024Mobile ? '40px' : "120px", // Tab 之间的间距
                            titleFontSize: '16px' // Tab 文本字体大小
                        },
                    },
                }}
                >
                <Tabs style={{paddingTop: isMobile?'80px':'120px'}} activeKey={tabState} centered onChange={setTabState}>
                    <Tabs.TabPane tab={is1024Mobile ? '项目' : "PROJECT / 项目"} key="1">
                        <ProjectPage></ProjectPage>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={is1024Mobile ? '研究' : "RESEARCH / 研究"} key="2">
                        <ResearchPage></ResearchPage>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={is1024Mobile ? '纪录' : "PHOTOGRAPH / 纪录"} key="3">
                        <PhotograghPage></PhotograghPage>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={is1024Mobile ? '关于' : "ABOUT / 关于"} key="4">
                        <AboutPage></AboutPage>
                    </Tabs.TabPane>
                </Tabs>
            </ConfigProvider>
        </div>

    );
}

function ProjectPage(){
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 576);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isOneColumn, setIsOneColumn] = useState(window.innerWidth <= 1240);

    useEffect(() => {
        const handleResize = () => setIsOneColumn(window.innerWidth <= 1240);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return   <div class="grid-container">
        <div class="column grid-item-2">
            <ProjectCard img={TouchDiffusionImg} title="TouchDiffusion" time="2024 June" awardImg={DIA} url="TouchDiffusion" isMobile={isMobile}/>
            <ProjectCard img={EyeSayImg} title="EyeSay | 眼语" time="2023 September" url="EyeSay" isLong={true} isMobile={isMobile}/>
            <ProjectCard img={FunFineImg} title="FunFine | 饭饭之交" time="2022 May" url="FunFine" isLong={true} isMobile={isMobile}/>
        </div>
        <div class="column grid-item-2">
            <ProjectCard img={PhotonImg} title="Photon | 光子" time="2024 October" url="Photon" isLong={true} isMobile={isMobile}/>
            <ProjectCard img={WhereImg} title="Where | 谓尔" time="2023 April"  url="Where" isLong={true} isMobile={isMobile}/> 
            <ProjectCard img={LeftLifeImg} title="Left Life | 余生" time="2022 October"  url="LeftLife" isMobile={isMobile}/>  
            
        </div>
        <div class="Copyright" >© Zhaoqu Jiang 2024. All rights reserved.</div>        
    </div>
}

function ResearchPage(){
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1024);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <div class="grid-container">
        <div class="column grid-item-2">
            <ProjectCard img={IntuitModelingImg} url="IntuitModeling" title="IntuitModeling: Intuitively Tangible 3D Modeling via Multiform and Flexible Self-Shape-Sensing Devices" time="In Progress" isMobile={isMobile}/>
            <ProjectCard img={HybridPrototypeImg} url="HybridPrototype" title="A Hybrid Prototype Method Combining Physical Models and Generative Artificial Intelligence to Support Creativity in Conceptual Design" time="2024 November | TOCHI" isMobile={isMobile}/>
            <ProjectCard img={SocializeChatImg} url="SocializeChat" title="SocializeChat: a GPT-based AAC Tool for Social Communication Through Eye Gazing" time="2023 October | UbiComp Adjunct" isMobile={isMobile}/>   
            
        </div>
        <div class="column grid-item-2">
            <ProjectCard img={FusionProtorImg} url="FusionProtor" title="FusionProtor: A Mixed-Prototype Tool for Component-level Physical-to-Virtual 3D Transition and Simulation in Conceptual Design" time="In Progress" isLong={true}isMobile={isMobile}/>
            <ProjectCard img={IEDSImg} url="IEDS" title="IEDS: Exploring an Intelli-Embodied Design Space combining designer, AR, and GAI to support conceptual design" time="In Progress" isMobile={isMobile}/>
            <ProjectCard img={VRGestureImg} url="VRGesture" title="Elicitation and Evaluation of Hand-based Interaction Language for 3D Conceptual Design in Mixed Reality" time="2024 March | IJHCS" isMobile={isMobile}/>
        </div>
        <div class="Copyright" >© Zhaoqu Jiang 2024. All rights reserved.</div>        
    </div>
}

function PhotograghPage(){
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1240);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1240);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return   <div class="grid-container">

        <div class="grid-item-4">
            <div class='blogLargeTitle'>VLOG / 影像特辑</div>
        </div>

        <div class={isMobile?"grid-item-4":"grid-item-2"}>
            <ProjectCard img={XiningImg} title={"西宁以西"} time="2024 Augest" url="Xining" intro={"西北的风总是很粗狂，玫瑰从未在这片土地生长。如果你喜欢浪漫，这里也并非寸草不生。"} isMobile={true}/>
        </div>

        <div class={isMobile?"grid-item-4":"grid-item-2"}>
            {/* <ProjectCard img={XiningImg} title={"西宁以西"} time="2024 Augest" intro={"西北的风总是很粗狂，玫瑰从未在这片土地生长。如果你喜欢浪漫，这里也并非寸草不生。"} isMobile={true}/> */}
        </div>

        <Spacer></Spacer>

        <div class="grid-item-4">
            <div class='blogLargeTitle'>PHOTO / 摄影时刻</div>
        </div>

        <div class={isMobile?"grid-item-4":"grid-item-2"}>
            <ProjectCard img={FaxisiImg} title={"祈福纳祥"} time="2024 September" url="Faxisi" isMobile={isMobile}/>
        </div>
        <div class={isMobile?"grid-item-4":"grid-item-2"}>
            <ProjectCard img={QingshanhuImg} title={"青山湖循迹"} time="2024 Augest" url="Qingshanhu" isMobile={isMobile}/>
        </div>

        <div class="grid-item-4">
            <div class="Copyright" >© Zhaoqu Jiang 2024. All rights reserved.</div>        
        </div>       
    </div>
}

function handleClick(url) {
    window.open(url, '_blank'); // 在新标签页打开链接
}

function AboutPage(){
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1024);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scrollToElement = (id) => {
    const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    
    const handleAnchorClick = (e, link) => {
        e.preventDefault(); // 阻止默认行为，避免 URL 中出现锚点
        scrollToElement(link.href.replace('#', '')); // 手动滚动到目标元素
    };

    return   <Row>
        <div class="grid-container">
            <div class="column grid-item-3" id="introduction">
                {/* <Spacer value="60px"></Spacer> */}
                <div class="grid-item-1">
                    <img src={avatar} alt="Avatar"className={styles.Avatar} />
                </div>
                <Spacer value="60px"></Spacer>

                <div class='blogLargeTitle'>Jummmp Jiang / 蒋招衢</div>
                <div class='blogText'>浙江大学计算机科学与技术学院设计学硕士在读。主要研究生成式人工智能与人机交互，在有形用户界面、虚拟现实和空间计算等领域具有丰富的研究经验；参与的研究成果在TOCHI、IJHCS、UbiComp等顶尖期刊及会议上发表；设计作品曾荣获中国设计智造大奖（DIA）设计新锐奖。研究之余，对交互体验设计、摄影、移动应用设计与开发、游戏设计与开发有浓厚兴趣。游戏作品曾获腾讯高校游戏创意制作大赛优胜奖。</div>
                <Spacer></Spacer>

                <div class='blogLargeTitle' id="contact">CONTACT ME / 联系方式</div>
                <ul>
                    <li><div class='blogText'>E-mail: zhaoqujiang@zju.edu.cn</div></li>
                    <li><div class='blogText'>Tel: +86 17365870070</div></li>
                </ul>
                <Spacer value="0px"></Spacer>

                <div class='blogLargeTitle' id="honor">HONOR / 所获荣誉</div>
                <ul>
                    <li><div class='blogText'>2024 | 中国设计智造大奖（DIA） 概念组-设计新锐奖（全球 TOP39）</div></li>
                    <li><div class='blogText'>2024 | 中国高校计算机大赛-智能交互创新赛 最具创意奖、国家级三等奖</div></li>
                    <li><div class='blogText'>2024 | 中国研究生“美丽中国”创新设计大赛 国家级一等奖</div></li>
                    <li><div class='blogText'>2023 | 中国高校计算机大赛-智能交互创新赛 国家级一等奖</div></li>
                    <li><div class='blogText'>2023 | 中国高校计算机大赛-移动应用创新赛 国家级二等奖、社会责任创新奖</div></li>
                    <li><div class='blogText'>2023 | 全国“互联网+”大学生创新创业大赛 国家级金奖、全国季军</div></li>
                    <li><div class='blogText'>2023 | 全国第十三届挑战杯大学生创业计划竞赛 国家级金奖</div></li>
                    <li><div class='blogText'>2022 | 腾讯高校游戏创意制作大赛 优胜奖</div></li>
                    <li><div class='blogText'>2022 | 微信小程序应用开发赛 华东赛区二等奖</div></li>
                    <li><div class='blogText'>2022 | 靳埭强设计奖（UI赛道） 入围奖</div></li>
                </ul>
                <Spacer value="0px"></Spacer>

                <div class='blogLargeTitle' id="publications">PUBLICATIONS / 发表著作</div>
                <ul>
                    <li onClick={() => handleClick('https://doi.org/10.1145/3689433')} style={{ cursor: 'pointer' }}>
                        <div class='blogText'>A Hybrid Prototype Method Combining Physical Models and Generative Artificial Intelligence to Support Creativity in Conceptual Design</div>
                        <div class='blogCaption'>Hongbo Zhang, Pei Chen, Xuelong Xie, <b>Zhaoqu Jiang,</b> Zihong Zhou, Lingyun Sun </div>
                        <Chip text="TOCHI 2024"></Chip> 
                    </li>
                    <li onClick={() => handleClick('https://doi.org/10.1016/j.ijhcs.2023.103198')} style={{ cursor: 'pointer' }}>
                        <div class='blogText'>Elicitation and Evaluation of Hand-based Interaction Language for 3D Conceptual Design in Mixed Reality</div>
                        <div class='blogCaption'>Lingyun Sun, Hongbo Zhang, Pei Chen, <b>Zhaoqu Jiang,</b> Xuelong Xie, Zihong Zhou, Xuanhui Liu, Xiaoyu Chen </div>
                        <Chip text="IJHCS 2024"></Chip>
                    </li>
                    <li onClick={() => handleClick('http://epub.cnipa.gov.cn/cred/CN117292085B')} style={{ cursor: 'pointer' }}>
                        <div class='blogText'>《一种支持三维建模的实体交互控制方法及其装置》</div>
                        <div class='blogCaption'>周子洪;陈培;<b>蒋招衢;</b>谢学泷;张宏博;孙凌云</div>
                        <Chip text="中国发明专利"></Chip> 
                    </li>
                    <li onClick={() => handleClick('https://doi.org/10.1145/3594739.3610705')} style={{ cursor: 'pointer' }}>
                        <div class='blogText'>SocializeChat: Utilizing AAC in a GPT-4 Enhanced Approach for Social Communication Through Eye-Gazing</div>
                        <div class='blogCaption'>Yuyang Fang, Yunkai Xu, Zhuyu Teng, <b>Zhaoqu Jiang,</b> Wei Xiang </div>
                        <Chip text="UbiComp 2023 Adjunct"></Chip>
                    </li>
                </ul>
                <div class="Copyright" >© Zhaoqu Jiang 2024. All rights reserved.</div>        
            </div>
            <div class="fixed-column grid-item-1" style={{display:isMobile?"none":""}}>
                <ConfigProvider theme={{components: {Anchor: {colorPrimary: '#333333'},},}}>
                    <Anchor items={AboutAnchorItems} onClick={handleAnchorClick} />
                </ConfigProvider>
            </div>
        </div>
    </Row>
}

const AboutAnchorItems=[
    {
        key: 'part-1',
        href: '#introduction',
        title: 'INTRODUCTION 介绍',
    },
    {
        key: 'part-2',
        href: '#contact',
        title: 'CONTACT ME 联系方式',
    },
    {
        key: 'part-3',
        href: '#honor',
        title: 'HONOR 所获荣誉',
    },
    {
        key: 'part-4',
        href: '#publications',
        title: 'PUBLICATIONS 发表著作',
    },
]

export default Home;
